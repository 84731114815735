h1, h2, h3, h4, h5, h6, p {
  color: #484955;
}


h2 {
  font-size: 38px;
  color: #484955;
}

h4 {
  font-size: 18px;
  margin: 24px 0 8px;
}

p {
  line-height: 1.8;
}

.MuiButton-contained{
  background-color: #000000 !important;
  border-radius: 24px !important;
  text-transform: none !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  padding: 8px 24px !important;
  color: #c8eacd !important;
}

.MuiButton-contained:hover {
  background-color: #000000b5 !important;
}

.TopNav a, .NavDrawer a {
  color: rgb(72, 73, 85);
  font-size: 1.2rem;
  text-decoration: none;
}

.TopNav a:hover, .NavDrawer a:hover {
  color: rgba(72, 73, 85, 0.7);
  font-size: 1.2rem;
  text-decoration: none;
}